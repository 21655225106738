'use client'

import { createAnonUser } from '@/server-actions/auth'
import { wrapServerActionCall } from '@/server-actions/wrapServerAction'
import { useQuery } from '@tanstack/react-query'

function AnonUserWrapper({ children, isAuthenticated }: { children: React.ReactNode; isAuthenticated: boolean }) {
  useQuery({
    queryKey: ['anon-user'],
    queryFn: async () => await wrapServerActionCall(createAnonUser)(),
    enabled: !isAuthenticated,
  })

  return <>{children}</>
}

export default AnonUserWrapper
